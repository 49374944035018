import {
  useDownloadCSVMutation,
  useGetKPIsQuery,
} from "@api/orchestrator/orchestrator.services";
import {
  NESTED_TABS,
  TabItem,
} from "@components/UI/Tabbar/models/Tabbar.models";
import {
  ENTERPRISE_TAB_ENUM,
  NATION_TAB_ENUM,
  REGION_TAB_ENUM,
} from "@containers/Indicators/models/tabs.models";
import { useCheckboxHandler } from "@hooks/Checkbox/useCheckboxHandler";
import { useGraphData } from "@hooks/Detail/useGraphData";
import { useGoalsOutletContext } from "@hooks/useGoalsOutletContext";
import useSelectedEnterprise from "@hooks/useSelectedEnterprise";
import useSelectedRegion from "@hooks/useSelectedRegion";
import { Company, Region } from "@models/Companies/companies.model";
import { Domain, FilterPeriod, Quarter } from "@models/Detail/detail.model";
import {
  Chart,
  DeliveryRegimeType,
  LableDomain,
} from "@models/Glossary/Glossary.model";
import {
  CompaniesKPI,
  Granularity,
  Period,
} from "@models/Indicators/indicators.model";
import {
  DENOMINATOR_DETAIL_SUMMARY,
  DOMAIN,
  NESTED_TAB_DETAIL_HEADER_COLUMN,
  NUMERATOR_DETAIL_SUMMARY,
  VALIDATED_INDICATOR_DETAIL_SUMMARY,
} from "@utils/enum/domain.enum";
import { EXPORT_TYPE } from "@utils/enum/exportType.enum";
import { FILTERS_KEY } from "@utils/enum/filtersKey.enum";
import { KPI_CATEGORY_VALUE } from "@utils/enum/kpiCategory";
import {
  SSN_LABEL,
  SSN_TYPE_DENOMINATOR,
  SSN_TYPE_NUMERATOR,
  SSN_TYPE_VALUE,
} from "@utils/enum/ssn.enum";
import { ZONE } from "@utils/enum/zone.enum";
import { getIndicatorsObj } from "@utils/function/indicators";
import { t } from "i18next";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { locationTab } from "src/redux/selectors/locationTab.selectors";
import { storeLocationTab } from "src/redux/slices/locationTab.slice";
import { selectionChanged } from "src/redux/slices/selections.slice";
import { useAppDispatch, useAppSelector } from "src/store";
import { IndicatorsDetailContainerProps } from "../DetailContainer";
import { Tabs } from "../DetailViewModel";
import { DetailTableInCardProps } from "../components/DetailTableInCard/model/detailTableInCard";

export function useNestedTabDetailViewModel(
  regions: Region[],
  kpiCategory: IndicatorsDetailContainerProps["category"],
  currentKpi: IndicatorsDetailContainerProps["kpi"],
  domain: Domain,
  isTargetShown?: boolean,
  zone?: ZONE
) {
  const dispatch = useAppDispatch();

  const localityTab = useAppSelector(locationTab) as Tabs;

  const setLocalityTab = useCallback(
    (path: string) => {
      dispatch(storeLocationTab(path));
    },
    [dispatch]
  );

  const { glossaries, regions: regionsMap } = useGoalsOutletContext();

  const { selectedRegion } = useSelectedRegion(regionsMap);
  const { selectedCompanyId } = useSelectedEnterprise(selectedRegion);
  const selectedCompany = selectedRegion.companies[selectedCompanyId];

  const setSelectedCompany = useCallback(
    (newRegion: Region, newCompany: Company) => {
      dispatch(
        selectionChanged({
          region: newRegion.id,
          company: newCompany.code,
        })
      );
    },
    [dispatch]
  );

  const [activeNestedTab, nestedTabReducer] = useReducer(
    tabReducer,
    NESTED_TABS.VALIDATED_INDICATOR
  );

  const hasScale = activeNestedTab !== NESTED_TABS.VALIDATED_INDICATOR;

  const changeNestedTabHandler = (
    nestedTab: (typeof nestedTabItems)[number]
  ) => {
    nestedTabReducer(nestedTab);
    onApplyFilterHandler(
      defaultPeriod,
      defaultGranularity,
      defaultDeliveryRegime
    );
  };

  const glossaryForSelectedKpi = glossaries.get(currentKpi)!;
  const deliveryRegimes = glossaryForSelectedKpi.deliveryRegime;

  const deliveryRegimeForCurrentTab = deliveryRegimes?.[activeNestedTab];

  const defaultDeliveryRegime = deliveryRegimeForCurrentTab?.at(0);

  const [selectedDeliveryRegime, setSelectedDeliveryRegime] = useState(
    defaultDeliveryRegime
  );

  useEffect(() => {
    if (deliveryRegimes)
      setSelectedDeliveryRegime(deliveryRegimes?.[activeNestedTab].at(0));
  }, [activeNestedTab, deliveryRegimes]);

  const isPower = kpiCategory === KPI_CATEGORY_VALUE.POWER;
  const isGoalDetail = kpiCategory === KPI_CATEGORY_VALUE.GOAL;

  const [isLinearScale, setIsLinearScale] = useState(true);

  const periods = useMemo(() => {
    const periodsForLineChart = glossaryForSelectedKpi.periods.find(
      (period) => period.id === Chart.LINE_CHART
    )?.values;

    return periodsForLineChart ?? defaultPeriods;
  }, [glossaryForSelectedKpi.periods]);

  const granularities = useMemo(() => {
    const granularitiesForLineChart = glossaryForSelectedKpi?.granularity.find(
      (period) => period.id === Chart.LINE_CHART
    )?.values;

    return granularitiesForLineChart ?? defaultPeriods;
  }, [glossaryForSelectedKpi]);

  const defaultPeriod = periods[2];
  const defaultGranularity =
    granularities.find((item) => item.id === Granularity.Monthly) ??
    granularities[0];

  const [selectedPeriod, setSelectedPeriod] = useState<FilterPeriod>(
    defaultPeriod as FilterPeriod
  );

  const [selectedGranularity, setSelectedGranularity] =
    useState<LableDomain>(defaultGranularity);

  const onApplyFilterHandler = (
    period: typeof defaultPeriod,
    granularity: typeof defaultGranularity,
    deliveryRegime?: typeof defaultDeliveryRegime
  ) => {
    setSelectedPeriod(period as FilterPeriod);
    setSelectedGranularity(granularity);
    setSelectedDeliveryRegime(deliveryRegime);
  };

  const getKpiApiResponse = useGetKPIsQuery({
    KPIs: [currentKpi],
    period: selectedPeriod.id,
    sampling: selectedGranularity.id,
    region: localityTab !== NATION_TAB_ENUM.ID ? selectedRegion.id : undefined,
    company:
      localityTab === ENTERPRISE_TAB_ENUM.ID ? selectedCompany.code : undefined,
  });

  const { selectedKPI } = useMemo(() => {
    if (
      getKpiApiResponse.isSuccess &&
      getKpiApiResponse.currentData &&
      getKpiApiResponse.currentData.kpis.length > 0
    ) {
      const indicatorsObj = getIndicatorsObj(
        glossaries,
        getKpiApiResponse.currentData.kpis
      );

      const selectedKPI = indicatorsObj.shift();
      return {
        selectedKPI,
      };
    }

    return {};
  }, [getKpiApiResponse.currentData, getKpiApiResponse.isSuccess, glossaries]);

  const availableGranularities = useMemo(() => {
    const granularitiesMap = granularities.reduce((acc, granularity) => {
      acc[granularity.id] = granularity;
      return acc;
    }, {} as Record<string, LableDomain>);

    const validCombinations: Record<string, LableDomain[]> = {};

    periods.forEach((period) => {
      const commonStaticPeriods = commonPeriodsTable.static?.[period.id] ?? [];
      const commonDynamicPeriods =
        Object.entries(commonPeriodsTable.dynamic ?? []).find(([key]) =>
          period.id.includes(key)
        )?.[1] ?? [];

      const tabSpecificStaticPeriods =
        tabSpecificPeriodTable[kpiCategory]?.[localityTab]?.[activeNestedTab]
          ?.static?.[period.id] ?? [];
      const tabSpecificDynamicPeriods =
        Object.entries(
          tabSpecificPeriodTable[kpiCategory]?.[localityTab]?.[activeNestedTab]
            ?.dynamic ?? []
        ).find(([key]) => period.id.includes(key))?.[1] ?? [];

      const _granularities = [
        commonStaticPeriods,
        commonDynamicPeriods,
        tabSpecificStaticPeriods,
        tabSpecificDynamicPeriods,
      ].flat();

      if (_granularities.length > 0) {
        validCombinations[period.id] = _granularities
          .map((id) => granularitiesMap[id])
          .filter(Boolean);
      }
    });

    return validCombinations;
  }, [activeNestedTab, granularities, kpiCategory, localityTab, periods]);

  const availablePeriods = useMemo(() => {
    const _filteredPeriods = periods.filter(
      (period) => period.id in availableGranularities
    );

    const _availablePeriods: FilterPeriod[] = _filteredPeriods.map((item) => {
      const _isTarget = item.id.includes("Q");

      return {
        id: item.id,
        description: item.description,
        //isTarget if id is like "2Q2023"
        isTarget: _isTarget,
        category: _isTarget ? "filters.sections.targetsPeriods" : undefined,
      };
    });

    return _availablePeriods;
  }, [availableGranularities, periods]);

  const getTableLabel = useCallback(
    (granularity: string, referenceDate: Date) => {
      const refactoredReferenceDate =
        referenceDate.getTimezoneOffset() === 0
          ? referenceDate
          : new Date(
              referenceDate.getTime() +
                referenceDate.getTimezoneOffset() * 60 * 1000
            );
      switch (granularity) {
        case Granularity.Monthly:
          return t("indicators.tables.dateMonthly", {
            date: refactoredReferenceDate,
          });
        case Granularity.Quarterly:
          const quarter = Math.floor(
            refactoredReferenceDate.getMonth() / 3 + 1
          ) as Quarter;
          return t(`indicators.tables.dateQuarterly.${quarter}`, {
            year: refactoredReferenceDate.getFullYear(),
          });
        default:
          return t("indicators.tables.dateDaily", {
            date: refactoredReferenceDate,
          });
      }
    },
    []
  );

  const headerColumn =
    domain === DOMAIN.NATION
      ? NESTED_TAB_DETAIL_HEADER_COLUMN.REGION
      : NESTED_TAB_DETAIL_HEADER_COLUMN.ENTERPRISE;

  const accessorKey = () => {
    const ssn_type:
      | typeof SSN_TYPE_NUMERATOR
      | typeof SSN_TYPE_DENOMINATOR
      | typeof SSN_TYPE_VALUE =
      activeNestedTab === NESTED_TABS.VALIDATED_INDICATOR
        ? SSN_TYPE_VALUE
        : activeNestedTab === NESTED_TABS.NUMERATOR
        ? SSN_TYPE_NUMERATOR
        : SSN_TYPE_DENOMINATOR;

    return ssn_type === SSN_TYPE_VALUE &&
      domain === DOMAIN.NATION &&
      isTargetShown
      ? ssn_type.SSN_AND_EXTRASSN
      : selectedDeliveryRegime && selectedDeliveryRegime.id === SSN_LABEL.SSN
      ? ssn_type.SSN
      : selectedDeliveryRegime &&
        selectedDeliveryRegime.id === SSN_LABEL.EXTRA_SSN
      ? ssn_type.EXTRA_SSN
      : ssn_type.SSN_AND_EXTRASSN;
  };

  const getSummaryText = useCallback((): string => {
    switch (activeNestedTab) {
      case NESTED_TABS.NUMERATOR: {
        switch (domain) {
          case DOMAIN.NATION:
            return zone
              ? zone === ZONE.A
                ? NUMERATOR_DETAIL_SUMMARY.NATION_ZONE_A
                : NUMERATOR_DETAIL_SUMMARY.NATION_ZONE_B
              : NUMERATOR_DETAIL_SUMMARY.NATION;
          case DOMAIN.REGION:
            return NUMERATOR_DETAIL_SUMMARY.REGION;
          case DOMAIN.ENTERPRISE:
            return NUMERATOR_DETAIL_SUMMARY.ENTERPRISE;
        }
        break;
      }
      case NESTED_TABS.VALIDATED_INDICATOR: {
        switch (domain) {
          case DOMAIN.NATION:
            return isGoalDetail
              ? zone
                ? zone === ZONE.A
                  ? VALIDATED_INDICATOR_DETAIL_SUMMARY.NATION_ZONE_A
                  : VALIDATED_INDICATOR_DETAIL_SUMMARY.NATION_ZONE_B
                : VALIDATED_INDICATOR_DETAIL_SUMMARY.NATION
              : VALIDATED_INDICATOR_DETAIL_SUMMARY.IS_GOAL;
          case DOMAIN.REGION:
            return isGoalDetail
              ? VALIDATED_INDICATOR_DETAIL_SUMMARY.REGION_GOAL
              : VALIDATED_INDICATOR_DETAIL_SUMMARY.REGION_POWER;
          case DOMAIN.ENTERPRISE:
            return VALIDATED_INDICATOR_DETAIL_SUMMARY.ENTERPRISE;
        }
        break;
      }
      case NESTED_TABS.DENOMINATOR: {
        switch (domain) {
          case DOMAIN.NATION:
            return zone
              ? zone === ZONE.A
                ? DENOMINATOR_DETAIL_SUMMARY.NATION_ZONE_A
                : DENOMINATOR_DETAIL_SUMMARY.NATION_ZONE_B
              : DENOMINATOR_DETAIL_SUMMARY.NATION;
          case DOMAIN.REGION:
            return DENOMINATOR_DETAIL_SUMMARY.REGION;
          case DOMAIN.ENTERPRISE:
            return DENOMINATOR_DETAIL_SUMMARY.ENTERPRISE;
        }
      }
    }
  }, [activeNestedTab, domain, isGoalDetail, zone]);

  const nationalKpiValues = selectedKPI?.values;

  const companies = useMemo(() => {
    if (!selectedRegion || domain === DOMAIN.NATION) {
      return [];
    }

    const companies =
      selectedKPI?.regions.find((region) => region.id === selectedRegion?.id)
        ?.companies ?? [];

    return companies.map((company) => ({
      ...company,
      navigate: () => {
        setLocalityTab(ENTERPRISE_TAB_ENUM.ID);
        setSelectedCompany(
          selectedRegion,
          selectedRegion.companies[company.id]
        );
      },
    }));
  }, [
    domain,
    selectedKPI?.regions,
    selectedRegion,
    setLocalityTab,
    setSelectedCompany,
  ]);

  const getCheckboxItems = useMemo(() => {
    if (domain === DOMAIN.NATION) {
      const _regions: DetailTableInCardProps["dataTable"] =
        selectedKPI?.regions.map((region) => ({
          ...region,
          navigate: () => {
            dispatch(
              selectionChanged({
                region: region.id,
                company: region.companies?.at(0)?.id,
              })
            );
            setLocalityTab(REGION_TAB_ENUM.ID);
          },
        })) ?? [];

      if (
        nationalKpiValues &&
        !_regions.find((item) => item.id === FILTERS_KEY.NATIONAL_VALUE_ID)
      ) {
        _regions.push({
          id: FILTERS_KEY.NATIONAL_VALUE_ID,
          description: t("shared.charts.lineChart.nationalValue"),
          values: nationalKpiValues,
        });
      }
      return _regions;
    } else {
      const region = selectedKPI?.regions.find(
        (region) => region.id === selectedRegion?.id
      );
      const regionToAdd = {
        id: selectedRegion?.id ?? "",
        description: selectedRegion?.description ?? "",
        values: region?.values ?? [],
        type: "",
      } as CompaniesKPI;
      const _companies = [...companies, regionToAdd];
      return _companies;
    }
  }, [
    companies,
    dispatch,
    domain,
    nationalKpiValues,
    selectedKPI?.regions,
    selectedRegion?.description,
    selectedRegion?.id,
    setLocalityTab,
  ]);

  const checkboxHandler = useCheckboxHandler(
    domain,
    getCheckboxItems,
    isGoalDetail,
    domain === DOMAIN.REGION ? selectedRegion : undefined,
    zone
  );

  const kpiValues = checkboxHandler.checkedItems;

  const lineChartXAxis = useMemo(() => {
    return (
      kpiValues.at(0)?.values.map(({ referenceDate }) => {
        return getTableLabel(selectedGranularity.id, referenceDate);
      }) ?? []
    );
  }, [getTableLabel, kpiValues, selectedGranularity.id]);

  const lineChartTarget = isPower
    ? undefined
    : kpiValues.at(0)?.values.at(0)?.target;

  const companyToShow = useMemo(() => {
    if (
      domain === DOMAIN.ENTERPRISE &&
      selectedKPI &&
      selectedKPI.regions.at(0)?.companies &&
      selectedCompany
    ) {
      return selectedKPI.regions
        .at(0)
        ?.companies?.find(
          (company: CompaniesKPI) => company.id === selectedCompany.code
        );
    }
  }, [domain, selectedKPI, selectedCompany]);

  const graphData = useGraphData(
    domain,
    kpiValues,
    activeNestedTab,
    selectedDeliveryRegime,
    isTargetShown
  );

  const [getCSVData] = useDownloadCSVMutation();

  const downloadCSV = useCallback(async () => {
    const regions =
      domain === DOMAIN.ENTERPRISE
        ? []
        : domain === DOMAIN.NATION && checkboxHandler.checkedItems
        ? checkboxHandler.checkedItems.map(({ id }) => ({
            regionId: id,
          }))
        : domain === DOMAIN.REGION &&
          selectedRegion &&
          checkboxHandler.checkedItems
        ? [
            {
              regionId: selectedRegion.id,
              companies: checkboxHandler.checkedItems.map(({ id }) => ({
                companyId: id,
              })),
            },
          ]
        : [
            {
              regionId: selectedRegion.id,
              companies: [{ companyId: companyToShow?.id ?? "" }],
            },
          ];

    getCSVData({
      glossaryId: currentKpi,
      periodId: selectedPeriod.id,
      sampling: selectedGranularity.id,
      exportType:
        (selectedDeliveryRegime &&
          exportTypes[selectedDeliveryRegime.id][activeNestedTab]) ??
        EXPORT_TYPE.KPI,
      regions,
    });
  }, [
    activeNestedTab,
    checkboxHandler.checkedItems,
    companyToShow,
    currentKpi,
    domain,
    getCSVData,
    selectedDeliveryRegime,
    selectedGranularity,
    selectedPeriod,
    selectedRegion,
  ]);

  const getCheckboxData = (zone?: string) => {
    switch (domain) {
      case DOMAIN.NATION:
        return {
          title: t(`indicators.detail.nation.checkbox.title`),
          searchPlaceholder: t(
            `indicators.detail.nation.checkbox.searchPlaceholder`
          ),
          fieldsetAriaLabel: t(
            `indicators.detail.nation.checkbox.fieldsetAriaLabel`
          ),
          resetAriaLabel: t(`indicators.detail.nation.checkbox.resetAriaLabel`),
          ...checkboxHandler,
        };
      case DOMAIN.REGION:
        return {
          title: t("indicators.detail.region.checkbox.title"),
          searchPlaceholder: t(
            "indicators.detail.region.checkbox.searchPlaceholder"
          ),
          regions,
          ...checkboxHandler,
        };

      case DOMAIN.ENTERPRISE:
        return undefined;
    }
  };

  const unshiftNationalValues = useMemo(() => {
    const fixedRowId =
      domain === DOMAIN.NATION
        ? FILTERS_KEY.NATIONAL_VALUE_ID
        : selectedRegion.id;

    return kpiValues.sort(function (item_one, item_two) {
      return item_one.id === fixedRowId
        ? -1
        : item_two.id === fixedRowId
        ? 1
        : 0;
    });
  }, [domain, kpiValues, selectedRegion.id]);

  return {
    lineChartXAxis,
    headerColumn,
    lineChartTarget,
    accessorKey,
    isLinearScale,
    setIsLinearScale,
    getSummaryText,
    isGoalDetail,
    graphData,
    kpiValues: unshiftNationalValues,
    availablePeriods,
    availableGranularities,
    hasScale,
    changeNestedTabHandler,
    downloadCSV,
    getCheckboxData,
    activeNestedTab,
    nestedTabItems,
    selectedPeriod,
    selectedGranularity,
    onApplyFilterHandler,
    deliveryRegimeForCurrentTab,
    selectedDeliveryRegime,
    setSelectedDeliveryRegime,
    isFetching: getKpiApiResponse.isFetching,
    setLocalityTab,
    setSelectedCompany,
    getTableLabel,
    selectedKPI,
  };
}

const nestedTabItems = [
  {
    id: NESTED_TABS.VALIDATED_INDICATOR,
    label: "navBar.indicators.validatedIndicator",
  },
  { id: NESTED_TABS.NUMERATOR, label: "navBar.indicators.numerator" },
  { id: NESTED_TABS.DENOMINATOR, label: "navBar.indicators.denominator" },
] satisfies TabItem[];

type NestedTabs = (typeof nestedTabItems)[number]["id"];

const tabReducer = (
  state: NESTED_TABS,
  selectedTab: (typeof nestedTabItems)[number]
) => selectedTab.id;

const commonPeriodsTable: DynamicPeriodTable = {
  static: {
    [Period.LastMonth]: [Granularity.Daily, Granularity.Monthly],
    [Period.LastThreeMonths]: [
      Granularity.Daily,
      Granularity.Monthly,
      Granularity.Quarterly,
    ],
    [Period.LastSixMonths]: [Granularity.Monthly, Granularity.Quarterly],
    [Period.LastYear]: [Granularity.Monthly, Granularity.Quarterly],
    [Period.All]: [Granularity.Quarterly],
  },
};

const tabSpecificPeriodTable: TabSpecificPeriodTable = {
  [KPI_CATEGORY_VALUE.GOAL]: {
    nation: {
      indicator: {
        dynamic: {
          [Period._2Q]: [Granularity.Monthly, Granularity.Quarterly],
        },
      },
    },
  },
};

type PeriodTable = { [periodId: string]: string[] };

type DynamicPeriodTable = {
  static?: PeriodTable;
  dynamic?: PeriodTable;
};

type TabSpecificPeriodTable = {
  [key in KPI_CATEGORY_VALUE]?: {
    [key in Tabs]?: {
      [key in NestedTabs]?: DynamicPeriodTable;
    };
  };
};

const defaultPeriods = [] as LableDomain[];

const exportTypes = {
  [DeliveryRegimeType.Ssn_Extra_Ssn]: {
    [NESTED_TABS.NUMERATOR]: EXPORT_TYPE.NUMERATOR,
    [NESTED_TABS.DENOMINATOR]: EXPORT_TYPE.DENOMINATOR,
    [NESTED_TABS.VALIDATED_INDICATOR]: EXPORT_TYPE.KPI,
  },
  [DeliveryRegimeType.Ssn]: {
    [NESTED_TABS.NUMERATOR]: EXPORT_TYPE.NUMERATOR_SSN,
    [NESTED_TABS.DENOMINATOR]: EXPORT_TYPE.DENOMINATOR_SSN,
    [NESTED_TABS.VALIDATED_INDICATOR]: EXPORT_TYPE.KPI_SSN,
  },
  [DeliveryRegimeType.Extra_Ssn]: {
    [NESTED_TABS.NUMERATOR]: EXPORT_TYPE.NUMERATOR_EXTRA_SSN,
    [NESTED_TABS.DENOMINATOR]: EXPORT_TYPE.DENOMINATOR_EXTRA_SSN,
    [NESTED_TABS.VALIDATED_INDICATOR]: EXPORT_TYPE.KPI_EXTRA_SSN,
  },
};
